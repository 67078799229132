import {isFuture, isPast, isToday} from 'date-fns';

export enum IpoStatus {
  Active = 'Aktiv',
  Listed = 'Noterad',
  Upcoming = 'Kommande',
  Today = 'Första handelsdag',
  Waiting = 'Inväntar första handelsdag',
}

export function getIpoStatus(subFrom: string, subTo: string, listDate: string) {
  const from = new Date(subFrom);
  const to = new Date(subTo);
  const listed = new Date(listDate);

  const sameDay = isToday(listed);
  if (sameDay) return IpoStatus.Today;

  const isListed = isPast(listed);
  if (isListed) return IpoStatus.Listed;

  const isActive = (isPast(from) && isFuture(to)) || isToday(from) || isToday(to);
  if (isActive) return IpoStatus.Active;

  const isWaiting = isPast(from) && isFuture(listed);
  if (isWaiting) return IpoStatus.Waiting;

  const isUpcoming = isFuture(from);
  if (isUpcoming) return IpoStatus.Upcoming;

  return IpoStatus.Listed;
}
