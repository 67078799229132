import Link from 'next/link';
import React from 'react';

import {SocialButtons} from './social-buttons';

const links = [
  {name: 'Villkor', href: '/policy'},
  {name: 'Sajtkarta', href: '/sitemap.xml'},
  {name: 'Företagsuppgifter', href: '/om'},
];

export function Footer() {
  return (
    <div className="container w-full mx-auto sm:max-w-screen-2xl">
      <footer className="flex flex-col py-6 space-y-6 text-sm border-t md:flex-row md:items-center md:space-y-0 md:justify-between text-dark">
        <div className="md:order-last">
          <SocialButtons />
        </div>
        <div className="flex flex-col md:flex-row">
          <div>
            &copy; {new Date().getFullYear()}{' '}
            <Link href="/">
              <a className="underline text-dark">IPO.se</a>
            </Link>{' '}
          </div>
          <div className="inline-flex items-center">
            {links.map((link) => (
              <React.Fragment key={link.name}>
                <svg
                  width="2"
                  height="2"
                  fill="currentColor"
                  className="inline-block mx-2 first:hidden md:first:inline-block"
                  aria-hidden="true"
                >
                  <circle cx="1" cy="1" r="1" />
                </svg>
                <Link href={link.href}>
                  <a className="text-dark hover:underline">{link.name}</a>
                </Link>
              </React.Fragment>
            ))}
          </div>
        </div>
      </footer>
    </div>
  );
}
