import {getActiveIposVars, useGetActiveIpos} from '@/lib/api/getActiveIpos';
import {filterActiveIpos} from '@/lib/utils';
import {MailIcon} from '@heroicons/react/outline';
import Link from 'next/link';
import React, {Dispatch, SetStateAction} from 'react';
import {BiPlayCircle} from 'react-icons/bi';

import {PodcastIcon} from '../images/podcast-icon';
import {ButtonOutline} from './button';
import {Footer} from './footer';

const useGetNavItems = () => {
  const {data} = useGetActiveIpos(getActiveIposVars);
  const activeIpos = filterActiveIpos(data?.ipos).length;

  return [
    {item: 'Nyheter', href: '/'},
    {
      item: (
        <span className="relative">
          Noteringar
          {!!activeIpos && (
            <span className="inline-block px-1 py-0.5 -top-0.5 -right-3 absolute shadow-sm text-[9px] leading-none font-semibold rounded-full dark:text-primary-100 dark:bg-primary-500/75 text-primary-700 bg-primary-100 last:mr-0 space-x-2">
              {activeIpos}
            </span>
          )}
        </span>
      ),
      href: '/noteringar',
    },
    // {item: 'Emissioner', href: '/emissioner'}, // TODO: implement emissioner
    {
      item: (
        <>
          <BiPlayCircle size={20} className="mr-1 text-primary" /> IPO Play{' '}
        </>
      ),
      href: '/play',
    },
    {
      item: (
        <>
          <PodcastIcon size={20} className="mr-1 text-purple-500 fill-current" /> Podcast
        </>
      ),
      href: '/podcast',
    },
    {item: 'Om oss', href: '/om'},
  ];
};

type NavItemProps = {
  href: string;
  setIsOpen?: (val: boolean) => void;
  children: React.ReactNode;
};

function NavItem({href, children, setIsOpen}: NavItemProps) {
  return (
    <li className="border-b md:mx-2 md:border-0 sm:first-of-type:hidden lg:first-of-type:block">
      <Link href={href}>
        <a
          className="flex items-center px-3 py-4 font-semibold sm:py-3 md:text-sm text-foreground md:rounded-2xl hover:bg-background-hover dark:text-slate-300 dark:hover:text-slate-200"
          onClick={setIsOpen ? () => setIsOpen(false) : undefined}
        >
          {children}
        </a>
      </Link>
    </li>
  );
}

export function Nav() {
  const getNavItems = useGetNavItems();
  return (
    <nav aria-label="Primary Navigation">
      <ul className="hidden md:flex">
        {getNavItems.map((item, i) => (
          <NavItem key={i} href={item.href}>
            {item.item}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
}

export function MobileNav({setIsOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>}) {
  const getNavItems = useGetNavItems();
  return (
    <div className="flex flex-col justify-between h-full">
      <nav aria-label="Mobile Navigation">
        <ul className="flex flex-col">
          {getNavItems.map((item, i) => (
            <NavItem key={i} href={item.href} setIsOpen={setIsOpen}>
              {item.item}
            </NavItem>
          ))}
        </ul>
        <div className="container grid py-6">
          <ButtonOutline variant="secondary" size="sm" className="mr-1.5">
            <MailIcon width="18" height="18" className="mr-2" /> Prenumerera på nyhetsbrev
          </ButtonOutline>
        </div>
      </nav>
      <Footer />
    </div>
  );
}
