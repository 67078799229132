import Link from 'next/link';
import cx from 'classnames';
import {Icon, IconName} from './icon';
import {isExternalUrl} from '@/lib/utils';

const themeClasses = {
  primary:
    'bg-primary-600 text-primary-50 border-primary-600 hover:bg-primary-500 dark:hover:bg-primary-500 dark:hover:border-primary-600 focus:ring-primary-300 dark:focus:ring-primary-700',
  'primary-light':
    'bg-primary-100 text-primary-800 border-primary-200 hover:bg-primary-50 dark:text-primary-300 dark:border-primary-500/30 dark:hover:bg-primary-500/30 dark:bg-primary-500/20 focus:ring-primary-300 dark:focus:ring-primary-900',
  secondary:
    'text-white bg-black dark:bg-white dark:text-black border-foreground hover:bg-foreground/75 dark:hover:bg-[#ebebeb]',
  'secondary-outline':
    'text-black bg-transparent border dark:text-white dark:border-white dark:hover:bg-foreground/5',
  violet:
    'bg-violet-100 text-violet-800 border-violet-200 hover:bg-violet-50 dark:text-violet-300 dark:border-violet-500/30 dark:hover:bg-violet-500/30 dark:bg-violet-500/20focus:ring-violet-300 dark:focus:ring-violet-900',
};

const sizeClasses = {
  sm: 'px-4 py-1.5 text-sm',
  md: 'px-5 py-1.5',
  lg: 'px-6 py-2 text-lg font-semibold',
};

type Variant = keyof typeof themeClasses;
type Size = keyof typeof sizeClasses;
type ButtonProps = {
  variant?: Variant;
  size?: Size;
  href?: string;
  label?: string;
  icon?: IconName;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  outline?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

export function Button({
  label,
  onClick,
  href,
  variant = 'secondary-outline',
  icon,
  size = 'md',
  type = 'button',
}: ButtonProps) {
  const sharedClasses = cx(
    'inline-flex shrink-0 justify-center items-center space-x-3 rounded-md border border-dark focus:outline-none active:scale-[0.97]'
  );

  const classNames = cx(sharedClasses, themeClasses[variant], sizeClasses[size]);
  if (href) {
    return (
      <Link href={href}>
        <a
          className={classNames}
          onClick={onClick}
          aria-label={label}
          target={isExternalUrl(href) ? '_blank' : undefined}
          rel={isExternalUrl(href) ? 'noreferrer' : undefined}
        >
          <span>{label}</span>
          {icon && (
            <span className="w-5">
              <Icon name={icon} />
            </span>
          )}
        </a>
      </Link>
    );
  } else {
    return (
      <button className={classNames} onClick={onClick} aria-label={label} type={type}>
        <span>{label}</span>
        {icon && (
          <span className="w-5">
            <Icon name={icon} />
          </span>
        )}
      </button>
    );
  }
}

const mapBaseSize = {
  sm: 'h-8 px-4 text-sm',
  base: 'h-10 px-4',
  lg: 'h-12 px-6 text-lg',
} as const;

type Assign<T, U> = {
  [P in keyof (T & U)]: P extends keyof T ? T[P] : P extends keyof U ? U[P] : never;
};

type CustomButtonProps = {
  size?: 'sm' | 'base' | 'lg';
  as?: 'button' | 'a';
  variant?: 'primary' | 'secondary' | 'danger' | 'base';
  rounded?: boolean;
  children: React.ReactNode;
  type?: 'button' | 'submit';
};

export type ButtonProps2 =
  | Assign<React.ComponentPropsWithRef<'button'>, CustomButtonProps>
  | Assign<React.ComponentPropsWithRef<'a'>, CustomButtonProps>;

export function ButtonBase(props: ButtonProps2) {
  const {
    size = 'base',
    // variant = 'secondary',
    as = 'button',
    rounded = false,
    className,
    ...restProps
  } = props;
  const Element = as as React.ElementType;
  return (
    <Element
      className={cx(
        `inline-flex items-center font-semibold justify-center rounded-md whitespace-no-wrap
      disabled:opacity-50 disabled:cursor-not-allowed 
      focus:outline-none focus:shadow-outline
      transition duration-300`,
        rounded && 'rounded-3xl',
        mapBaseSize[size as keyof typeof mapBaseSize],
        className
      )}
      {...restProps}
    />
  );
}

const mapSolidVariant = {
  primary: `text-white bg-primary disabled:bg-primary
    hover:bg-opacity-80 active:bg-primary-700
    focus:bg-primary-700 focus:shadow-outline-primary`,
  danger: `text-white bg-red-500 disabled:bg-red
    hover:bg-red-600 active:bg-red-700
    focus:bg-red-700 focus:shadow-outline-danger`,
  secondary:
    'dark:bg-white dark:hover:bg-[#ebebeb] bg-foreground hover:bg-foreground-hover text-background focus:shadow-outline',
};

export function ButtonOld(props: ButtonProps2) {
  const {variant = 'secondary', className, ...restProps} = props;
  return (
    <ButtonBase
      className={cx(
        'whitespace-nowrap',
        mapSolidVariant[variant as keyof typeof mapSolidVariant],
        className
      )}
      {...restProps}
    />
  );
}

const mapOutlineVariant = {
  primary: `text-primary border-primary
    focus:bg-primary focus:shadow-outline-primary`,
  danger: `text-red-600 border-red-600
    hover:bg-red-600 active:bg-red-700
    focus:bg-red-700 focus:shadow-outline-danger`,
  secondary:
    'bg-transparent text-foreground border focus:text-foreground active:text-foreground focus:shadow-outline',
};

export function ButtonOutline(props: ButtonProps2) {
  const {variant = 'secondary', className, ...restProps} = props;
  return (
    <ButtonBase
      className={cx(
        `border-foreground hover:bg-white/5
      disabled:text-foreground disabled:bg-transparent
      active:text-foreground focus:text-foreground`,
        mapOutlineVariant[variant as keyof typeof mapOutlineVariant],
        className
      )}
      {...restProps}
    />
  );
}

const mapIconBaseSize = {
  sm: 'text-xl',
  base: 'text-2xl',
  lg: 'text-4xl',
};

export function IconButton(props: ButtonProps2) {
  const {size = 'base', as = 'button', rounded = false, ...restProps} = props;
  const Element = as as React.ElementType;
  return (
    <Element
      className={cx(
        `flex items-center justify-center p-3 rounded
      disabled:bg-transparent 
      disabled:opacity-50 disabled:cursor-not-allowed 
      focus:outline-none focus:shadow-outline`,
        rounded && 'rounded-full',
        mapIconBaseSize[size as keyof typeof mapIconBaseSize]
      )}
      {...restProps}
    />
  );
}
