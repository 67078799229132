export const AvanzaIcon = () => {
  return (
    <svg
      _ngcontent-ebt-c66=""
      viewBox="0 0 32 28"
      xmlns="http://www.w3.org/2000/svg"
      className="shrink-0 h-6 mr-1.5"
    >
      <path
        _ngcontent-ebt-c66=""
        fill="#00C281"
        d="M.388 19.495H4.52V31.57H.39M7.94 15.992h4.133v15.58H7.94M15.494 11.604h4.134V31.57h-4.134M23.055.2h4.133v31.37h-4.133"
      ></path>
    </svg>
  );
};
