import '../styles/globals.css';

import {ApolloProvider} from '@apollo/client';
import type {AppProps} from 'next/app';
import React from 'react';
import Script from 'next/script';
import {SWRConfig} from 'swr';

import {Footer} from '@/components/footer';
import {Header} from '@/components/header';
import {ThemeProvider} from 'next-themes';
import {useApollo} from '@/lib/apollo-client';
import {fetcher} from '@/lib/fetcher';
import {useAnalytics} from '@/lib/hooks/analytics';
import {GTM_ID} from '@/lib/config';

export default function App({Component, pageProps}: AppProps) {
  const apolloClient = useApollo(pageProps);
  useAnalytics();

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtm-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      {/* Crisp Chat */}
      <Script
        id="crisp-chat"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `window.$crisp=[["safe", true]];window.CRISP_WEBSITE_ID="911b272d-4bad-4ac7-8638-ac6a184b26d8";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
        }}
        defer
      />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider enableSystem={true} attribute="class">
          <SWRConfig value={{fetcher}}>
            <Header />
            <Component {...pageProps} />
            <Footer />
          </SWRConfig>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
}
