export const Nordnet = () => (
  <svg
    viewBox="0 0 443 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0 h-3 mr-1.5"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M378.097 0.00708008L246.584 108.553H310.649L442.161 0.00708008H378.097ZM131.512 0.00708008L0 108.553H64.063L195.576 0.00708008H131.512ZM195.576 108.554L246.584 108.553V0.00708008H195.576V108.554Z"
      className="fill-current"
    />
  </svg>
);
