import {Ipo} from '@/types/ipo';
import {getIpoStatus, IpoStatus} from './ipo-status';

export function capitalizeFirst(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Returns the reading time in minutes.
 *
 * @param text - The text to calculate the reading time for.
 * @returns The reading time in minutes.
 */
export function readingTime(text = ''): number {
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time;
}

/**
 * Trim length of a string.
 *
 * @param str - string to be trimmed.
 * @param length - trimemd length. Default: 80 characters.
 * @return trimmed string.
 */
export function trimLength(str = '', length = 80) {
  if (str.length > length) {
    return str.substring(0, str.lastIndexOf(' ', length)) + '…';
  }
  return str;
}

export function stripHtml(str = '') {
  return str.replace(/<[^>]*>?/gm, ''); // Strip html
}

export function snakeToNormal(str = '') {
  if (!str) return '';
  return str.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
}

export function getImageUrl(imgUrl = '') {
  return imgUrl.startsWith('/') ? `http://localhost:1337${imgUrl}` : imgUrl;
}

/**
 * Returns whether or not the app execution context is currently Server-Side or Client-Side
 */
export function isServerSide(): boolean {
  return typeof window === 'undefined';
}

export const isExternalUrl = (link: string): boolean => !link.startsWith('/');

const swedishNumbersMap = {
  noll: 0,
  en: 1,
  två: 2,
  tre: 3,
  fyra: 4,
  fem: 5,
  sex: 6,
  sju: 7,
  åtta: 8,
  nio: 9,
  tio: 10,
  elva: 11,
};

// Map to convert swedish numbers in words to numbers
export function swedishWordToNumbers(str: string) {
  if (!isNaN(parseInt(str.split(' ')[0]))) {
    return str;
  }

  const numberStr = str
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return swedishNumbersMap[word as keyof typeof swedishNumbersMap];
      }
      return word;
    })
    .join(' ');
  return numberStr;
}

export function filterActiveIpos(ipos: Ipo[] = []) {
  return ipos
    .filter((ipo) => {
      const status = getIpoStatus(
        ipo.subscription_period_from,
        ipo.subscription_period_to,
        ipo.first_trading_day
      );
      if (status !== IpoStatus.Listed) return true;
    })
    .reverse();
}
