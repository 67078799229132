import React from 'react';
import {Logo} from './logo';

export function LoadingSpinner() {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 text-light-gray animate-spin "
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export function LoadingDots() {
  return (
    <div className="flex p-5 space-x-3 opacity-40">
      <div
        className="w-3 h-3 rounded-full bg-foreground animate-pulse"
        style={{animationDelay: '50ms'}}
      ></div>
      <div
        className="w-3 h-3 rounded-full bg-foreground animate-pulse"
        style={{animationDelay: '400ms'}}
      ></div>
      <div
        className="w-3 h-3 rounded-full bg-foreground animate-pulse"
        style={{animationDelay: '800ms'}}
      ></div>
    </div>
  );
}

export function LoadingSection({variant = 'default'}: {variant?: 'dots' | 'default'}) {
  return (
    <div className="flex flex-col items-center justify-center h-screen max-w-screen">
      {variant === 'dots' ? (
        <LoadingDots />
      ) : (
        <>
          {/* IPO Logo */}
          <div
            className="relative p-5 bg-gray-100 rounded-full dark:bg-opacity-20"
            style={{
              animation: 'ping-slow 1.5s cubic-bezier(0, 0, 0.1, 1) infinite',
            }}
          ></div>
          <Logo className="absolute transition grayscale opacity-30" />
        </>
      )}
    </div>
  );
}
