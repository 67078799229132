import React from 'react';
import {useRouter} from 'next/router';
import {useFocus} from '@/lib/hooks/use-focus';
import cn from 'classnames';
import {BiSearch} from 'react-icons/bi';

type SearchFormProps = {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  handleSubmitCallback?: () => void;
};

export function SearchForm({inputRef, handleSubmitCallback, className}: SearchFormProps) {
  const router = useRouter();
  const [input, setInput] = React.useState(router.query.s ?? '');
  const [ref, setFocus] = useFocus();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | any) => {
    e.preventDefault();
    if (handleSubmitCallback) {
      handleSubmitCallback();
    }
    router.push(`/search?s=${e.currentTarget.elements.s.value}`);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  React.useEffect(() => {
    setFocus();
  }, [setFocus]);

  return (
    <form className={cn('w-full search-form', className)} action="search" onSubmit={handleSubmit}>
      <span className="absolute z-10 items-center justify-center w-8 h-full pt-3 pl-3 text-base font-normal leading-snug text-center bg-transparent rounded text-light-gray ">
        <BiSearch size={22} />
      </span>
      <input
        ref={inputRef || ref}
        type="search"
        id="s"
        name="s"
        placeholder="Sök efter IPO:s och nyheter"
        className="relative w-full px-2 py-2 pl-10 text-lg bg-transparent rounded outline-none placeholder-light-gray text-foreground focus:outline-none"
        onChange={handleOnChange}
        value={input}
      />
    </form>
  );
}
