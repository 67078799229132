import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {pageview} from '@/lib/gtm';
// import * as gtag from '@/lib/gtag';

export function useAnalytics() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
}
