import {useTheme} from 'next-themes';
import React, {useEffect, useState} from 'react';
import {BiMoon, BiSun} from 'react-icons/bi';

import {IconButton} from './icon-button';

export function ThemeToggle() {
  const [mounted, setMounted] = useState(false);
  const {resolvedTheme, setTheme} = useTheme();
  const isDarkMode = resolvedTheme === 'dark';
  const handleThemeToggle = React.useCallback(
    () => setTheme(isDarkMode ? 'light' : 'dark'),
    [isDarkMode, setTheme]
  );

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);

  return (
    <IconButton onClick={handleThemeToggle} aria-label="Toggle dark mode">
      {mounted && isDarkMode ? <BiSun size={22} /> : <BiMoon size={22} />}
    </IconButton>
  );
}
