import {CheckCircleIcon as CheckCircleIconOutline} from '@heroicons/react/outline';
import {CheckCircleIcon} from '@heroicons/react/solid';
import {FaArrowLeft, FaArrowUp} from 'react-icons/fa';
import {FiArrowRight, FiMail} from 'react-icons/fi';
import {GoPlay} from 'react-icons/go';
import {AvanzaIcon} from './icons/avanza';
import {Flag} from './icons/flag';
import {Nordnet} from './icons/nordnet';
import {LoadingSpinner} from './loading';

export type IconName = keyof typeof iconMap;

const iconMap = {
  'arrow-right': FiArrowRight,
  'arrow-up': FaArrowUp,
  'arrow-left': FaArrowLeft,
  // 'broken-link': BrokenLink,
  'check-circle-outline': CheckCircleIconOutline,
  'check-circle': CheckCircleIcon,
  // 'chevron-down': ChevronDownIcon,
  // 'chevron-left': ChevronLeftIcon,
  // 'chevron-right': ChevronRightIcon,
  // 'code-light': CodeLightIcon,
  // 'cross-circle-outline': CrossCircleOutlineIcon,
  // 'external-link': ExternalLinkIcon,
  'loading-spinner': LoadingSpinner,
  play: GoPlay,
  avanza: AvanzaIcon,
  nordnet: Nordnet,
  'se-flag': Flag,
  // api: ApiIcon,
  // bars: BarsIcon,
  // calendar: CalendarIcon,
  // check: CheckIcon,
  // close: CloseIcon,
  // code: CodeIcon,
  // collapse: CollapseIcon,
  // database: DatabaseIcon,
  // discord: DiscordIcon,
  // exclamation: ExclamationIcon,
  // expand: ExpandIcon,
  // gear: GearIcon,
  // github: GitHubIcon,
  // gitpod: GitpodIcon,
  // info: InfoIcon,
  // lightning: LightningIcon,
  // markdown: MarkdownIcon,
  mail: FiMail,
  // moon: MoonIcon,
  // notion: NotionIcon,
  // php: PHPIcon,
  // plus: PlusIcon,
  // question: QuestionIcon,
  // search: SearchIcon,
  // sign: SignIcon,
  // sun: SunIcon,
  // users: UsersIcon,
};

export function Icon({name}: {name: IconName}) {
  const IconComponent = iconMap[name];
  return <IconComponent />;
}
