export const isProd = process.env.NODE_ENV === 'production';
export const API_ENDPOINT =
  process.env.NEXT_PUBLIC_API_ENDPOINT ?? isProd ? 'https://api.ipo.se' : 'http://localhost:1337';

export const GA_TRACKING_ID = 'UA-97402990-1';
export const GTM_ID = 'GTM-NL739GF';
export const FB_TRACKING_ID = '';

export const SITE_META = {
  name: 'IPO.se',
  title: 'IPO.se - din plats för noteringar & emissioner',
  description:
    'IPOer och börsnoteringar. IPO.se levererar nyheter och information om börsnoteringar på den svenska IPO-marknaden.',
  type: 'website',
  twitter: '@iponyheter',
  image: 'https://ipo.se/static/images/banner.png',
};

export const colors = {
  primary: '#3eaf8c',
  primaryLight: '#74e2bc', // use with black text
  primaryDark: '#007f5f', // use with black text
  primaryMuted: '#3eaf8cAA',
  darkOnly: '#202020',
  secondary: '#b1d8c6',
  error: '#D8000C',
  danger: '#EB5757',
};
