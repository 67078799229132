import React from 'react';
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';

function SocialButton({...props}) {
  return (
    <a rel="me noreferrer noopener nofollow" target="_blank" className="text-dark" {...props} />
  );
}

export function SocialButtons() {
  const iconSize = 20;
  return (
    <div className="flex justify-between space-x-4 align-center lg:jusify-start">
      <SocialButton href="https://twitter.com/iponyheter">
        <FaTwitter size={iconSize} />
        <span className="sr-only">IPO.se på Twitter</span>
      </SocialButton>
      <SocialButton href="https://www.facebook.com/iposverige/">
        <FaFacebook size={iconSize} />
        <span className="sr-only">IPO.se på Facebook</span>
      </SocialButton>
      <SocialButton href="https://www.instagram.com/iposverige/">
        <FaInstagram size={iconSize} />
        <span className="sr-only">IPO.se på Instagram</span>
      </SocialButton>
      <SocialButton href="https://www.youtube.com/channel/UCAHqWlwbaqh7NMafpTGN6EA">
        <FaYoutube size={iconSize} />
        <span className="sr-only">IPO.se på YouTube</span>
      </SocialButton>
    </div>
  );
}
