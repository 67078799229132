import {gql, useQuery} from '@apollo/client';
import {Ipo} from '@/types/ipo';
import {format} from 'date-fns';

export const GET_ACTIVE_IPOS = gql`
  query GetActiveIpos($limit: Int = 27, $date: Date) {
    ipos(limit: $limit, sort: "first_trading_day:asc", where: {first_trading_day_gte: $date}) {
      id
      title
      slug
      first_trading_day
      subscription_period_from
      subscription_period_to
    }
  }
`;

export const getActiveIposVars = {limit: 27, date: format(new Date(), 'yyyy-MM-dd')};

type GetActiveIpoVars = {limit: number; date: string};
type GetActiveIposResponse = {ipos: Ipo[]};

export function useGetActiveIpos(variables?: GetActiveIpoVars) {
  return useQuery<GetActiveIposResponse, GetActiveIpoVars>(GET_ACTIVE_IPOS, {
    variables: {...getActiveIposVars, ...variables},
  });
}
