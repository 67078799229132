import React, {Fragment, useCallback, useEffect} from 'react';
import cx from 'classnames';
import Link from 'next/link';
import {useOutsideClick} from '@/lib/hooks/use-click-outside';
import {useEscape} from '@/lib/hooks/use-escape';
import {useFocus} from '@/lib/hooks/use-focus';
import {Transition} from '@headlessui/react';
import {MenuAlt4Icon, XIcon} from '@heroicons/react/outline';
import {BiSearch} from 'react-icons/bi';
import {Button} from './button';

import {IconButton} from './icon-button';
import {Logo} from './logo';
import {MobileNav, Nav} from './nav';
import {SearchForm} from './search-form';
import {ThemeToggle} from './theme-toggle';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const [inputRef, setInputFocus] = useFocus();
  const handleSubmitCallback = useCallback(() => {
    setIsSearchOpen(false);
  }, []);

  useOutsideClick(headerRef, () => setIsSearchOpen(false));
  useEffect(() => setInputFocus(), [setInputFocus, isSearchOpen]);
  useEscape(() => {
    setIsMenuOpen(false);
    setIsSearchOpen(false);
  });

  return (
    <>
      <header
        ref={headerRef}
        className={cx(
          `flex justify-center h-[var(--header-height-mobile)] md:h-[var(--header-height)] top-0 z-50 right-0 left-0 shadow-header dark:shadow-header-dark fixed w-full bg-background dark:bg-opacity-90 backdrop-blur-md`
        )}
      >
        <div className="flex items-center justify-between w-full px-3 sm:container sm:max-w-screen-2xl">
          {isSearchOpen ? (
            <SearchForm
              inputRef={inputRef}
              handleSubmitCallback={handleSubmitCallback}
              className="max-w-lg"
            />
          ) : (
            <div className="flex items-center">
              <div className="flex mr-2 -ml-1 sm:-ml-3">
                <Link href="/">
                  <a
                    aria-label="Länk till startsida"
                    className="p-3 rounded-full hover:bg-background-hover active:scale-[0.96]"
                  >
                    <Logo />
                  </a>
                </Link>
              </div>
              <Nav />
            </div>
          )}
          <div className="flex">
            <ul className="flex items-center">
              <li className={cx(isSearchOpen ? 'hidden' : '', 'mr-1.5 hidden lg:flex')}>
                <Button variant="secondary-outline" size="sm" label="Prenumerera" />
              </li>
              <ThemeToggle />
              <IconButton
                id="search"
                onClick={() => setIsSearchOpen((prev) => !prev)}
                aria-label="Search on site"
              >
                <BiSearch size={22} />
              </IconButton>
              {/* <IconButton aria-label="Mina bokmärken">
                   <BiBookmarks size={22} />
                  </IconButton> */}

              <IconButton
                className="z-50 flex md:hidden"
                onClick={() => setIsMenuOpen((prev) => !prev)}
                aria-label="Toggle menu"
              >
                {!isMenuOpen ? (
                  <MenuAlt4Icon width={24} height={24} className="fill-current text-foreground" />
                ) : (
                  <>
                    <span className="sr-only">Close panel</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </>
                )}
              </IconButton>
            </ul>
          </div>
        </div>
      </header>
      <SlideOver isOpen={isMenuOpen} setIsOpen={setIsMenuOpen}>
        <MobileNav setIsOpen={setIsMenuOpen} />
      </SlideOver>
    </>
  );
}

type SlideOverProps = {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

function SlideOver({children, isOpen, setIsOpen}: SlideOverProps) {
  const mobileNavRef = React.useRef<HTMLDivElement | null>(null);
  useOutsideClick(mobileNavRef, () => isOpen && setIsOpen(false));

  return (
    <div
      className={`fixed inset-0 z-40 h-screen bg-black/20 dark:bg-opacity-30 transition-opacity ${
        isOpen ? 'block w-screen' : 'bg-opacity-0 w-0'
      }`}
    >
      <div
        ref={mobileNavRef}
        className={cx(
          `z-50 w-full max-w-lg h-screen mt-[var(--header-height-mobile)] md:hidden bg-header bg-opacity-50`,
          isOpen ? 'block' : 'w-0'
        )}
      >
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform transition ease-in-out duration-250"
          enterFrom="translate-x-[-100%]"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-250"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-[-100%]"
        >
          <div className="flex flex-col h-full pb-[55px] overflow-auto text-foreground bg-background shadow-xl">
            <div className="flex-1 first-letter:relative">{children}</div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
