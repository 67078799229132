import {ButtonHTMLAttributes, DetailedHTMLProps} from 'react';

type IconButtonProps = {
  onClick?: (e?: React.MouseEventHandler) => void;
  className?: string;
  children: React.ReactNode;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function IconButton({onClick, children, className = '', ...props}: IconButtonProps) {
  return (
    <li className={`flex ml-2 cursor-pointer first:ml-0 justify-center items-center ${className}`}>
      <button
        className={`bg-transparent rounded-lg focus:outline-none text-foreground md:hover:bg-background-hover p-2`}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    </li>
  );
}
